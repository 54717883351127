import React from "react"
import Layout from "../components/layout"
import Breadcrumb from "../components/Breadcrumb"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

const LecturerCooperation = ({ data }) => {
  const {
    contentfulLecturerCooperation: {
      slug,
      title,
      content: { content },
    },
  } = data

  const crumbs = [
    {
      name: "首頁",
      url: "/",
    },
    {
      name: title,
      url: `/${slug}`,
    },
  ]
  return (
    <Layout showTitleTemplate={false}>
      <div className="wrapper my-12">
        <Breadcrumb crumbs={crumbs} />
      </div>
      <div className="wrapper mb-24 sm:mb-36 lg:max-w-5xl">
        <h1 className="mb-12 text-3xl !leading-normal tracking-wide font-bold text-neutral-800 sm:text-5xl">
          {title}
        </h1>
        <div className="markdown inner-page mx-auto">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
        </div>
      </div>
    </Layout>
  )
}

LecturerCooperation.propTypes = {
  data: PropTypes.object,
}

export default LecturerCooperation

export const pageQuery = graphql`
  query {
    contentfulLecturerCooperation(slug: { eq: "lecturer-cooperation" }) {
      slug
      title
      content {
        content
      }
    }
  }
`
